import axios from "axios";

const ENDPOINT_FIRMA = process.env.VUE_APP_FIRMA;
const ENDPOINT_FIRMA_CERTIFICADO = process.env.VUE_APP_FIRMA_CERTIFICADO;
const CLIENT_FIRMA_CERTIFICADO = process.env.VUE_APP_FIRMA_CERTIFICADO_CLIENT_ID;
const KEY_FIRMA_CERTIFICADO = process.env.VUE_APP_FIRMA_CERTIFICADO_API_KEY;

export default {

  traetoken() {

    const axios = require('axios');
let data = JSON.stringify({
  "client_id": "43048505400eb445aa546c9007541772",
  "api_key": "4b7a546c8420b888d42b6e22dca00807",
});

let config = {
  method: 'GET',
  maxBodyLength: Infinity,
  url: 'https://pre-marketplace-cl.thsigne.com/api/auth',
  headers: { 
    'Content-Type': 'application/json', 
    'Access-Control-Allow-Origin': '*'
  },
  data : data
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});
  //  let credenciales={
  //     "client_id": CLIENT_FIRMA_CERTIFICADO,
  //     "api_key": KEY_FIRMA_CERTIFICADO,
  //     "consumer": "app"
  //   }
  //   console.log(ENDPOINT_FIRMA_CERTIFICADO + "auth")
  //   let config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //   }
  //   return this.callApi(ENDPOINT_FIRMA_CERTIFICADO + "auth", "post",credenciales,config);
  },
  traecertificados(data,token) {
    data={
      "tipo_cert" : "1",
      "rut" : "12345678-9",
      "serie" : "123456789",
      "nombre" : "Nombre",
      "apellidos" : "Apellidos",
      "email" : "mail@email.com",
      "telefono" : "+56123456789",
       "url_retorno" : "http://urlretornocliente?tr=123456789"
      };
      let config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
    return this.callApi(ENDPOINT_FIRMA_CERTIFICADO + "certRequest", "post",data,config);
  },
  
  traeusuarios() {
    return this.callApi(ENDPOINT_PATH + "usuarios", "post");
  },
  editar(id) {
    return this.callApi(
      ENDPOINT_PATH + "usuario/" + id + "/editar/usuario",
      "post"
    );
  },
  traeroles() {
    return this.callApi(ENDPOINT_PATH + "usuario/roles", "post");
  },
  estado(usuario) {
    return this.callApi(ENDPOINT_PATH + "usuario/estado", "post", usuario);
  },
  guardarsimulacion(usuario) {
    return this.callApi(ENDPOINT_PATH + "LineaDesgravamen/PropuestaCrear", "post",usuario);
  },
  
  simulacionSeguro(usuario) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/Simular", "post",usuario);
  },
  simulacionCreditoVigente(usuario) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SimularCreditoVigente", "post",usuario);
  },
  guardarsimulacionSeguro(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/ContratarPlan", "post",plan);
  },  
  guardarplanSeguro(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/PlanSeleccionar", "post",plan);
  },
  guardarsimulacionLog(bitacora) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/SeguroSeleccionar", "post",bitacora);
  },
  traeplanSimulacion(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/TerminoCondicionLeer/"+plan, "post");
  },
  traeplanSimulacionSeleccionado(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/PlanSimuladoLeer/"+plan, "post");
  },
  traeplanContratado(plan) {
    return this.callApi(ENDPOINT_PATH + "MisSeguros/SeguroLeer/"+plan, "post");
  },
  traeDocumentos(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoConsultarEstados", "post",seguro);
  },
  traeMandato(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/MandatoLeer", "post",seguro);
  },
  traeRenuncia(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/SolicitudRenunciaLeer", "post",seguro);
  },
  guardarDocumento(seguro) {
    return this.callApi(ENDPOINT_PATH + "Documentos/DocumentoGrabar", "post",seguro);
  },
  contratar(seguro) {
    return this.callApi(ENDPOINT_PATH + "MisCotizaciones/SeguroContratar", "post",seguro);
  },
  traePreguntas() {
    return this.callApi(ENDPOINT_PATH + "Documentos/PreguntasListar", "post");
  },
  
  
  traeMediosPagos(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/MedioPagosListar/"+plan, "post");
  },  
  guardarSimulacionContratar(plan) {
    return this.callApi(ENDPOINT_PATH + "SimulacionSeguro/PlanContratar", "post",plan);
  },
  
  login(usuario) {
    return this.callApi(ENDPOINT_PATH + "Login/Ingreso", "post",usuario);
  },
  actualizar(usuario) {
    return this.callApi(
      ENDPOINT_PATH + "usuario/" + usuario.id + "/actualizar/usuario",
      "put",
      usuario
    );
  },
  eliminar(id) {
    return this.callApi(
      ENDPOINT_PATH + "usuario/" + id + "/eliminar",
      "delete"
    );
  },

  async callApi(ruta, method = false, data = false,config=false) {
    try {
      if (method == "post") {
        return await axios
          .post(ruta, data,config)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            let erdis = "";
            if (error.response.data.errors) {
              const er = error.response.data.errors;
              if (er.usuario) {
                erdis + " error usuario : ";
                er.usuario.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.nombre) {
                erdis + " error nombre : ";
                er.nombre.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.email) {
                erdis + " error email : ";
                er.email.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              if (er.password) {
                erdis = erdis + " error password : ";
                er.password.forEach((element) => {
                  erdis = erdis + " " + element;
                });
              }
              return { data: { success: false, message: erdis }, status: 200 };
            }
            return {
              data: { success: false, message: "Error no capturado" },
              status: 200,
            };
          });
      }
      if (method == "delete") {
        return await axios.delete(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "get") {
        return await axios.get(ruta, data).catch(function (error) {
          return false;
        });
      }
      if (method == "put") {
        return await axios
          .put(ruta, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      }
    } catch (error) {}
  },
};
